import React from 'react';

import logo from '../../static/images/doublet_h128.webp';

const SplashLoading = () => {
    return (
        <>
            <div className="innerDiv">
                <img src={logo} />
            </div>
            <div className="innerDiv">
                <span>&nbsp;</span>
            </div>
            <div className="innerDiv">
                <div id="circleG">
                    <div id="circleG_1" className="circleG"></div>
                    <div id="circleG_2" className="circleG"></div>
                    <div id="circleG_3" className="circleG"></div>
                </div>
            </div>
            <div className="innerDiv">
                <span>&nbsp;</span>
            </div>
            <div className="innerDiv">
                <p className="innerText">Loading...</p>
            </div>
        </>
        );
}

export default SplashLoading;