import React, { Component } from 'react'
import { Route, Navigate } from 'react-router-dom'

import { AppPaths } from '../../constants/AppPaths';
import authService from './AuthorizeService'

class AuthorizeRoute2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;
        var link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${'/authentication/login'}?${'returnUrl'}=${encodeURIComponent(returnUrl)}`;
        if (!ready) {
            return <div></div>;
        } else {
            const { element } = this.props;
            return authenticated ? element : <Navigate replace to={redirectUrl} />;
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}

const AuthorizeRoute = (props) => {
    const { path, element } = props;
    //console.log('AuthorizeRoute');
    //console.log(path);
    const [ready, setReady] = React.useState(false);
    const [authenticated, setAuthenticated] = React.useState(false);

    var link = document.createElement("a");
    link.href = path;
    //const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const returnUrl = path;
    const redirectUrl = `${AppPaths.Authentication_Login}?next=${encodeURIComponent(returnUrl)}`;

    const isMountedRef = React.useRef(null);

    // componentDidMount 
    React.useEffect(() => {
        isMountedRef.current = true;

        populateAuthenticationState();

        // unmount
        return () => isMountedRef.current = false;
    }, []);

    const populateAuthenticationState = async () => {
        const authenticated2 = await authService.isAuthenticated();
        setAuthenticated(authenticated2);
        setReady(true);
    }

    const authenticationChanged = async () => {
        setReady(false);
        setAuthenticated(false);

        await populateAuthenticationState();
    }

    return (
        <>
            {!ready ?
                <div></div>
                :
                authenticated ?
                    element
                    :
                    <Navigate to={redirectUrl} replace={true} />
            }
        </>
        );
}

export default AuthorizeRoute;
