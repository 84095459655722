import React from 'react';

const NotFound = () => {
    return (
        <>
            Not Found
        </>
        );
}

export default NotFound;