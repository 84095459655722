import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';

// import i18n (needs to be bundled ;))
import './i18n';

import ErrorBoundary from './components/common/ErrorBoundary';
import VersionCheck from './VersionCheck';

// hide console.log information in production
//console.log(process.env);
// DEBUG
if (process.env.NODE_ENV === 'production') {
    console.log = function () { }
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter basename={baseUrl}>
                    <React.Suspense fallback={
                        <div>
                            <p>Loading...</p>
                        </div>
                    }>
                        <VersionCheck />
                    </React.Suspense>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
