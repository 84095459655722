import React from 'react';
import axios from 'axios';

import { ThemeProvider } from '@mui/material/styles';

import { ApiPaths } from './constants/ApiPaths';

import SplashLoading from './components/common/SplashLoading';
import { myTheme } from './css/myTheme';

const DeepLink = React.lazy(() => import('./DeepLink'));

const VersionCheck = () => {
    const [dbInfo, setDbInfo] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState('');

    const isMountedRef = React.useRef(null);

    // componentDidMount 
    React.useEffect(() => {
        isMountedRef.current = true;

        getDbInfo();

        // unmount
        return () => isMountedRef.current = false;
    }, []);

    const getDbInfo = () => {
        console.log("getDbInfo");

        axios.get(`${ApiPaths.DbInfo}`)
            .then(res => {
                console.log(res.data);

                setDbInfo(res.data);
            }, error => {
                var errorMessage2 = 'Get DbInfo failed.  Please refresh again.';
                if (error.response) {
                    console.log(error.response);
                }
                else
                    console.log(error);
                setErrorMessage(errorMessage2);
            });
    }

    return (
        <>
            {dbInfo && dbInfo.webVersion === window.myVersion ?
                <>
                    <ThemeProvider theme={myTheme}>
                        <DeepLink />
                    </ThemeProvider>
                </>
                :
                <>
                    <div className="outerDiv">
                        {errorMessage.length > 0 &&
                            <div className="innerDiv">
                                <p className="innerTextError">{errorMessage}</p>
                            </div>
                        }
                        {!dbInfo ?
                            <>
                                <SplashLoading />
                            </>
                            :
                            <>
                                {dbInfo.webVersion != window.myVersion &&
                                    <div className="innerDiv">
                                        <p className="innerText">{`DoubleT2U has been updated to version ${dbInfo.webVersion}.  Please refresh your browser to get latest update.`}</p>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </>
            }
        </>
        );
}

export default VersionCheck;