import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        value: 0
    },
    reducers: {
        increment: state => {
            state.value += 1
        },
        decrement: state => {
            state.value -= 1
        },
        plus: (state, action) => {
            state.value += action.payload;
        },
        updateCount: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { increment, decrement, plus, updateCount } = cartSlice.actions

export const selectCount = state => state.cart.value

export default cartSlice.reducer