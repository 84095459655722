import { createSlice } from '@reduxjs/toolkit'

export const readySlice = createSlice({
    name: 'ready',
    initialState: {
        value: false
    },
    reducers: {
        setReady: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { setReady } = readySlice.actions

export const selectReady = state => state.ready.value

export default readySlice.reducer