import { createSlice } from '@reduxjs/toolkit'

export const accountSlice = createSlice({
    name: 'account',
    initialState: {
        id: 0,
        userName: "",
        name: "",
        nickName: "",
        icNo: "",
        hpNo: "",
        email: "",
        avatarImageUrl: "",
        withAgentFee: false,
        agentFee: 0,
        memberRole: "",
        firebaseUid: "",
        membershipNo: "",

        dateRenew: null,
        dateExpire: null,
        expired: false,
        beforeExpireDay: 0,

        referrerId: 0,
        referrerMembershipNo: "",
        referrerNickName: "",
        referrerMemberRole: "",
        referrerWithAgentFee: false,
        referrerAgentFee: 0,

        firebaseUser: null,
    },
    reducers: {
        setUserAccount: (state, action) => {
            state.id = action.payload.id;
            state.userName = action.payload.userName;
            state.name = action.payload.name;
            state.nickName = action.payload.nickName;
            state.icNo = action.payload.icNo;
            state.hpNo = action.payload.hpNo;
            state.email = action.payload.email;
            state.avatarImageUrl = action.payload.avatarImageUrl;
            state.withAgentFee = action.payload.withAgentFee;
            state.agentFee = action.payload.agentFee;
            state.memberRole = action.payload.memberRole;
            state.firebaseUid = action.payload.firebaseUid;
            state.membershipNo = action.payload.membershipNo;

            state.dateRenew = action.payload.dateRenew;
            state.dateExpire = action.payload.dateExpire;
            state.expired = action.payload.expired;
            state.beforeExpireDay = action.payload.beforeExpireDay;

            state.referrerId = action.payload.referrerId;
            state.referrerMembershipNo = action.payload.referrerMembershipNo;
            state.referrerNickName = action.payload.referrerNickName;
            state.referrerMemberRole = action.payload.referrerMemberRole;
            state.referrerWithAgentFee = action.payload.referrerWithAgentFee;
            state.referrerAgentFee = action.payload.referrerAgentFee;

            state.firebaseUser = action.payload.firebaseUser;
        },
        clearUserAccount: state => {
            state.id = 0;
            state.userName = "";
            state.name = "";
            state.nickName = "";
            state.icNo = "";
            state.hpNo = "";
            state.email = "";
            state.avatarImageUrl = "";
            state.withAgentFee = false;
            state.agentFee = 0;
            state.memberRole = "";
            state.firebaseUid = "";
            state.membershipNo = "";

            state.dateRenew = null;
            state.dateExpire = null;
            state.expired = false;
            state.beforeExpireDay = 0;

            state.referrerId = 0;
            state.referrerMembershipNo = "";
            state.referrerNickName = "";
            state.referrerMemberRole = "";
            state.referrerWithAgentFee = false;
            state.referrerAgentFee = 0;

            state.firebaseUser = null;
        }
    }
})

export const { setUserAccount, clearUserAccount } = accountSlice.actions

export const selectAccount = state => state.account

export default accountSlice.reducer