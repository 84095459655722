import React from 'react';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import validator from 'validator';

const MyPasswordInput = (props) => {
    const { label = 'Password', password, setPassword, errorMessage, setErrorMessage, useStrongPassword = true } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    const handleChangePassword = (e) => {
        var value = e.target.value.trim();
        setPassword(value);
        validateStrongPassword(value);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleBlur = (e) => {
        if (!useStrongPassword) return;

        if (validator.isStrongPassword(e.target.value.trim(), {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 0, minSymbols: 0
        })) {
            setErrorMessage('');
        } else {
            setErrorMessage('Password must be at least 8 characters long, and contain one uppercase and one lowercase character');
        }
    }

    const validateStrongPassword = (value) => {
        if (!useStrongPassword) return;

        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 0, minSymbols: 0
        })) {
            setErrorMessage('');
        } else {
            setErrorMessage('Password must be at least 8 characters long, and contain one uppercase and one lowercase character');
        }
    }

    return (
        <>
            <FormControl variant="outlined" fullWidth={true}
                error={errorMessage.length > 0}
            >
                <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleChangePassword}
                    //onBlur={handleBlur}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
                <FormHelperText id="my-helper-text">{errorMessage}</FormHelperText>
            </FormControl>
        </>
        );
}

export default MyPasswordInput;