const baseUrl = 'api';

export const ApiPaths = {
    DbInfo: `${baseUrl}/dbinfo`,
    SystemInfo: `${baseUrl}/systeminfo`,
    AppSetting: `${baseUrl}/appsetting`,
    Annoucements: `${baseUrl}/annoucements`,
    DeliveryPrices: `${baseUrl}/deliveryprices`,
    PackingPrices: `${baseUrl}/packingprices`,
    CalculateCollectFee: `${baseUrl}/calculatecollectfee`,
    CalculateDeliveryFee: `${baseUrl}/calculatedeliveryfee`,
    MemberRolePrices: `${baseUrl}/memberroleprices`,
    MembershipRenewPrices: `${baseUrl}/membershiprenewprices`,

    Login: `${baseUrl}/login`,
    Logout: `${baseUrl}/logout`,
    SignUp: `${baseUrl}/signup`,
    User: `${baseUrl}/user`,
    FirebaseUser: `${baseUrl}/firebaseuser`,

    Stores: `${baseUrl}/stores`,
    Categorys: `${baseUrl}/categorys`,
    GoldPrices: `${baseUrl}/goldprices`,

    Golds: `${baseUrl}/golds`,
    SearchGold: `${baseUrl}/searchgold`,
    SearchGolds: `${baseUrl}/searchgolds`,
    SearchGoldsCount: `${baseUrl}/searchgoldscount`,
    GoldsWVariants: `${baseUrl}/goldwvariants`,
    GoldsWVariantsWStore: `${baseUrl}/goldswvariantswstore`,
    productshare: `/share/productshare`,

    SearchGoldSets: `${baseUrl}/searchgoldsets`,

    GoldSeriess: `${baseUrl}/goldseriess`,
    GoldSeriessCount: `${baseUrl}/goldseriesscount`,
    GoldSeriesImages: `${baseUrl}/goldseriesimages`,

    Variants: `${baseUrl}/variants`,
    VariantsByGold: `${baseUrl}/variantsbygold`,

    Auctions: `${baseUrl}/auctions`,
    SearchAuctions: `${baseUrl}/searchauctions`,
    SearchAuctionItems: `${baseUrl}/searchauctionitems`,
    SearchAuctionItem: `${baseUrl}/searchauctionitem`,
    HighestBid: `${baseUrl}/highestbid`,
    MyHighestBid: `${baseUrl}/myhighestbid`,
    Bids: `${baseUrl}/bids`,

    Favorites: `${baseUrl}/favorites`,
    MyFavorites: `${baseUrl}/myfavorites`,
    SearchFavorites: `${baseUrl}/searchfavorites`,
    
    Cart: `${baseUrl}/cart`,
    CartItems: `${baseUrl}/cartitems`,
    CartItemCount: `${baseUrl}/cartitemcount`,

    Orders: `${baseUrl}/orders`,
    SearchOrder: `${baseUrl}/searchorder`,
    SearchOrders: `${baseUrl}/searchorders`,
    SearchOrderItem: `${baseUrl}/searchorderitem`,
    SearchOrderItems: `${baseUrl}/searchorderitems`,
    SearchInstallment: `${baseUrl}/searchinstallment`,

    DummyFPXARReceiver: `${baseUrl}/fpx/dummyfpxarreceiver`,
    FPXARLogs: `${baseUrl}/fpx/fpxarlogs`,
    FPXACLogs: `${baseUrl}/fpx/fpxaclogs`,
    FpxConfirm: `${baseUrl}/fpx/fpxconfirm`,
    FpxDirectResponse: `fpx/fpxdirectresponse`,
    FpxValidateTransaction: `fpx/fpxvalidatetransaction`,
    FpxStatus: `fpx/fpxstatus`,
    FpxEnquiry: `${baseUrl}/fpxenquiry`,

    Customers: `${baseUrl}/customers`,
    SearchCustomers: `${baseUrl}/searchcustomers`,

    Collectors: `${baseUrl}/collectors`,
    SearchCollectors: `${baseUrl}/searchcollectors`,
    SearchCollectorsOnly: `${baseUrl}/searchcollectorsonly`,

    ChangeOrderCustomer: `${baseUrl}/changeordercustomer`,
    ChangeOrderItemCustomer: `${baseUrl}/changeorderitemcustomer`,

    Addresss: `${baseUrl}/addresss`,

    OrderToPays: `${baseUrl}/ordertopays`,
    SearchOrderToPaysCount: `${baseUrl}/searchordertopayscount`,
    SearchOrderGroupToPays: `${baseUrl}/searchordergrouptopays`,
    SearchOrderGroupToPaysCount: `${baseUrl}/searchordergrouptopayscount`,
    CancelOrderGroupToPay: `${baseUrl}/cancelordergrouptopay`,

    Installments: `${baseUrl}/installments`,
    SearchInstallmentsCount: `${baseUrl}/searchinstallmentscount`,
    OpenTopUp: `${baseUrl}/opentopup`,
    SettlementGroups: `${baseUrl}/settlementgroups`,
    TempInstallments: `${baseUrl}/tempinstallments`,

    // collect
    OrderPrepareCollects: `${baseUrl}/orderpreparecollects`,
    OrderPrepareCollectsCount: `${baseUrl}/orderpreparecollectscount`,
    OrderAutoPrepareCollects: `${baseUrl}/orderautopreparecollects`,
    OrderAutoPrepareCollectsCount: `${baseUrl}/orderautopreparecollectscount`,
    SearchToCollects: `${baseUrl}/searchtocollects`,
    SearchToCollectsCount: `${baseUrl}/searchtocollectscount`,
    SearchToCollect: `${baseUrl}/searchtocollect`,
    OrderToCollects: `${baseUrl}/ordertocollects`,
    SearchToCollectGroupToPays: `${baseUrl}/searchtocollectgrouptopays`,
    SearchToCollectGroupToPaysCount: `${baseUrl}/searchtocollectgrouptopayscount`,
    ToCollectGroupToPayCancel: `${baseUrl}/tocollectgrouptopaycancel`,
    ToCollectCancel: `${baseUrl}/tocollectcancel`,

    OrderCompleteds: `${baseUrl}/ordercompleteds`,
    OrderGroupCancelleds: `${baseUrl}/ordergroupcancelleds`,

    // delivery
    OrderPrepareDeliverys: `${baseUrl}/orderpreparedeliverys`,
    OrderPrepareDeliverysCount: `${baseUrl}/orderpreparedeliveryscount`,
    OrderAutoPrepareDeliverys: `${baseUrl}/orderautopreparedeliverys`,
    OrderAutoPrepareDeliverysCount: `${baseUrl}/orderautopreparedeliveryscount`,
    SearchToDelivery: `${baseUrl}/searchtodelivery`,
    SearchToDeliverys: `${baseUrl}/searchtodeliverys`,
    SearchToDeliveryGroups: `${baseUrl}/searchtodeliveryGroups`,
    SearchToDeliverysCount: `${baseUrl}/searchtodeliveryscount`,
    SearchToDeliveryGroupsCount: `${baseUrl}/searchtodeliverygroupscount`,
    SearchToDeliveryGroupToPays: `${baseUrl}/searchtodeliverygrouptopays`,
    SearchToDeliveryGroupToPaysCount: `${baseUrl}/searchtodeliverygrouptopayscount`,
    ToDeliveryGroupToPayCancel: `${baseUrl}/todeliverygrouptopaycancel`,

    SearchCommissionByMonth: `${baseUrl}/searchcommissionbymonth`,
    SearchCommissions: `${baseUrl}/searchcommissions`,
    SearchCommissionsByAgentDS: `${baseUrl}/searchcommissionsbyagentds`,
    SearchMiscInvoiceCommissions: `${baseUrl}/searchmiscinvoicecommissions`,
    SearchCommissionAdjustments: `${baseUrl}/searchcommissionadjustments`,

    SendEmailVerificationCode: `${baseUrl}/sendemailverificationcode`,
    VerifyCode: `${baseUrl}/verifycode`,
    TempSignUp: `${baseUrl}/tempsignup`,
    NewMembership: `${baseUrl}/newmembership`,
    NewMembershipByHpNo: `${baseUrl}/newmembershipbyhpno`,
    CustomToken: `${baseUrl}/customtoken`,
    MembershipPassword: `${baseUrl}/membershippassword`,
    EmailVerified: `${baseUrl}/emailverified`,

    MembershipUpgrade: `${baseUrl}/membershipupgrade`,
    MembershipRenew: `${baseUrl}/membershiprenew`,

    // banner
    Banners: `${baseUrl}/banners`,
    BannerPopups: `${baseUrl}/bannerpopups`,
    BannerLives: `${baseUrl}/bannerlives`,
    BannerClick: `${baseUrl}/bannerclick`,
    BannerItemClick: `${baseUrl}/banneritemclick`,

    // voucher
    VoucherItemReadyToUse: `${baseUrl}/voucheritemreadytouse`,
    VoucherItemExpire: `${baseUrl}/voucheritemexpire`,
    VoucherItemUse: `${baseUrl}/voucheritemuse`,
    DistVoucherAmt: `${baseUrl}/distvoucheramt`,
    CalOrderGroupVoucherAmt: `${baseUrl}/calordergroupvoucheramt`,

    // e-Cash voucher
    ECashVoucherItemReadyToUse: `${baseUrl}/ecashvoucheritemreadytouse`,
    ECashVoucherItemReadyToUseCount: `${baseUrl}/ecashvoucheritemreadytousecount`,
    ECashVoucherItemExpire: `${baseUrl}/ecashvoucheritemexpire`,
    ECashVoucherItemUse: `${baseUrl}/ecashvoucheritemuse`,
    ECashVoucherTransfers: `${baseUrl}/ecashvouchertransfers`,
    DistECashVoucherAmt: `${baseUrl}/distecashvoucheramt`,
    CalOrderGroupECashVoucherAmt: `${baseUrl}/calordergroupecashvoucheramt`,

    // point
    BalancePoint: `${baseUrl}/balancepoint`,
    DistCreditPoint: `${baseUrl}/distcreditpoint`,
    PointSummary: `${baseUrl}/pointsummary`,
    PointDebit: `${baseUrl}/pointdebit`,
    PointCredit: `${baseUrl}/pointcredit`,

    // cash point
    BalanceCashPoint: `${baseUrl}/balancecashpoint`,
    DistCreditCashPoint: `${baseUrl}/distcreditcashpoint`,
    CashPointSummary: `${baseUrl}/cashpointsummary`,
    CashPointDebit: `${baseUrl}/cashpointdebit`,
    CashPointCredit: `${baseUrl}/cashpointcredit`,

    TabunganEmasDepositTemps: `${baseUrl}/tabunganemasdeposittemps`,
    TabunganEmasSummary: `${baseUrl}/tabunganemassummary`,
    TabunganEmasDebits: `${baseUrl}/tabunganemasdebits`,
    TabunganEmasCredits: `${baseUrl}/tabunganemascredits`,

    // DS List
    DSList: `${baseUrl}/dslist`,

    // test
    FirebaseSignUp: `${baseUrl}/firebasesignup`,

    FirebaseGoogle: `${baseUrl}/firebasegoogle`,
    FirebaseFcm: `${baseUrl}/firebasefcm`,

    //Image: `${baseUrl}/image`


    // GoldPrice Banner
    GoldPriceBanners: `${baseUrl}/goldpricebanners`,

    //DailyDiscovery
    DailyDiscoverys: `${baseUrl}/dailydiscoverys`,

    //Leaderboard
    AgentSaleLeaderboard: `${baseUrl}/agentsaleleaderboard`,
    AgentCcmLeaderboard: `${baseUrl}/agentccmleaderboard`,

    MiscInvoiceItems: `${baseUrl}/miscinvoiceitems`,

    Notifications: `${baseUrl}/notifications`,
    NotificationsCount: `${baseUrl}/notificationscount`,
    NotificationPromotionReads: `${baseUrl}/notificationpromotionreads`,

    Articles: `${baseUrl}/articles`,

    BankStatuss: `${baseUrl}/bankstatuss`,
};