import { configureStore, combineReducers } from '@reduxjs/toolkit';
import cartReducer from '../reducers/cartSlice';
import accountReducer from '../reducers/accountSlice';
import readyReducer from '../reducers/readySlice';

const rootReducer = combineReducers({
    cart: cartReducer,
    account: accountReducer,
    ready: readyReducer,
})

export const store = configureStore({
    reducer: rootReducer
})
