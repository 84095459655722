import { ApiPaths } from '../../constants/ApiPaths';

export class UserManager {
    async getUser() {
        let response = await fetch(ApiPaths.Login);
        if (!response.ok) {
            console.log("getUser fail");
            return null;
        }

        console.log("getUser ok");
        let user = await response.json();
        console.log(user);
        return user;
    }

    static get instance() { return userManager }
}

const userManager = new UserManager();

export default userManager;