export const BASE_URL = 'https://www.doublet2u.com';

export const UserAccountQueryParams = {
    Menu: 'menu',
    OrderStatus: 'orderStatus'
};

export const ProductsQueryParams = {
    CatId: 'catId',
    CatCode: 'catCode'
};

export const GoldImagePath = `${process.env.PUBLIC_URL}/images/gold`;

export const AppPaths = {
    Home: '/',
    About: '/about',
    AboutUs: '/aboutus',

    // authentication
    Authentication: '/authentication',
    Authentication_Login: '/authentication/login',

    Authentication_Login_Email: '/authentication/login/email',
    Authentication_Login_Email_Sent: '/authentication/login/email/sent',
    Authentication_Login_Email_Finish: '/authentication/login/email/finish',

    Authentication_Login_Phone: '/authentication/login/phone',
    Authentication_Login_Phone_Recaptcha: '/authentication/login/phone/recaptcha',
    Authentication_Login_Phone_VerifyCode: '/authentication/login/phone/verifycode',

    Authentication_ForgotPassword: '/authentication/forgotpassword',
    Authentication_ForgotPassword_Sent: '/authentication/forgotpassword/sent',
    Authentication_ForgotPassword_Input: '/authentication/forgotpassword/input',

    Authentication_ForgotPassword_Phone: '/authentication/forgotpassword/phone',
    Authentication_ForgotPassword_Phone_Recaptcha: '/authentication/forgotpassword/phone/recaptcha',
    Authentication_ForgotPassword_Phone_VerifyCode: '/authentication/forgotpassword/phone/verifycode',
    Authentication_ForgotPassword_Phone_NewPassword: '/authentication/forgotpassword/phone/newpassword',
    Authentication_ForgotPassword_Phone_Finish: '/authentication/forgotpassword/phone/finish',

    Authentication_ForgotPassword_Email: '/authentication/forgotpassword/email',
    Authentication_ForgotPassword_Email_SendCode: '/authentication/forgotpassword/email/sendcode',
    Authentication_ForgotPassword_Email_VerifyCode: '/authentication/forgotpassword/email/verifycode',
    Authentication_ForgotPassword_Email_NewPassword: '/authentication/forgotpassword/email/newpassword',
    Authentication_ForgotPassword_Email_Finish: '/authentication/forgotpassword/email/finish',

    Authentication_SignUp: '/authentication/signup',
    Authentication_SignUp_Sent: '/authentication/signup/sent',
    //Authentication_SignUp_Finish: '/authentication/signup/finish',
    Authentication_Logout: '/authentication/logout',

    Authentication_SignUp_Selection: '/authentication/signupv2/selection',
    Authentication_SignUp_InputPersonalData: '/authentication/signupv2/inputpersonaldata',
    Authentication_SignUp_VerifyEmailCode: '/authentication/signupv2/verifyemailcode',
    Authentication_SignUp_Recaptcha: '/authentication/signupv2/recaptcha',
    Authentication_SignUp_VerifyPhoneCode: '/authentication/signupv2/verifyphonecode',
    Authentication_SignUp_Payment: '/authentication/signupv2/payment',
    Authentication_SignUp_Finish: '/authentication/signupv2/finish',

    // user account
    UserAccount: '/useraccount',
    UserAccount_Home: '/useraccount/home',
    UserAccount_Settings: '/useraccount/settings',
    UserAccount_Profile: '/useraccount/profile',

    UserAccount_ChangePassword: '/useraccount/changepassword',

    UserAccount_ChangePassword_Phone_Recaptcha: '/useraccount/changepassword/phone/recaptcha',
    UserAccount_ChangePassword_Phone_VerifyCode: '/useraccount/changepassword/phone/verifycode',
    UserAccount_ChangePassword_Phone_NewPassword: '/useraccount/changepassword/phone/newpassword',
    UserAccount_ChangePassword_Phone_Finish: '/useraccount/changepassword/phone/finish',

    UserAccount_ChangePassword_Email_SendCode: '/useraccount/changepassword/email/sendcode',
    UserAccount_ChangePassword_Email_VerifyCode: '/useraccount/changepassword/email/verifycode',
    UserAccount_ChangePassword_Email_NewPassword: '/useraccount/changepassword/email/newpassword',
    UserAccount_ChangePassword_Email_Finish: '/useraccount/changepassword/email/finish',

    UserAccount_BecomeAgent: '/useraccount/becomeagent',
    UserAccount_BecomeAgent_Finish: '/useraccount/becomeagent/finish',

    UserAccount_Referrer: '/useraccount/referrer',
    UserAccount_Addresss: '/useraccount/addresss',
    UserAccount_Addresss_id: '/useraccount/addresss/:id',
    UserAccount_Addresss_New: '/useraccount/addresss/new',
    UserAccount_Customers: '/useraccount/customers',
    UserAccount_Collectors: '/useraccount/collectors',
    UserAccount_Favorites: '/useraccount/favorites',

    UserAccount_Notifications: '/useraccount/notifications',
    UserAccount_Notifications_OrderUpdates: '/useraccount/notifications/orderupdates',
    UserAccount_Notifications_Promotions: '/useraccount/notifications/promotions',

    UserAccount_Vouchers: '/useraccount/vouchers',
    UserAccount_Vouchers_History: '/useraccount/vouchers/history',
    UserAccount_Vouchers_History_Expired: '/useraccount/vouchers/history/expired',
    UserAccount_Vouchers_History_Used: '/useraccount/vouchers/history/used',

    UserAccount_ECashVouchers: '/useraccount/ecashvouchers',
    UserAccount_ECashVouchers_History: '/useraccount/ecashvouchers/history',
    UserAccount_ECashVouchers_History_Expired: '/useraccount/ecashvouchers/history/expired',
    UserAccount_ECashVouchers_History_Used: '/useraccount/ecashvouchers/history/used',
    UserAccount_ECashVouchers_Transfer_id: '/useraccount/ecashvouchers/transfer/:id',

    UserAccount_Point: '/useraccount/point',
    UserAccount_Point_Debit: '/useraccount/point/debit',
    UserAccount_Point_Credit: '/useraccount/point/credit',
    UserAccount_Point_Expire: '/useraccount/point/expire',

    UserAccount_TabunganEmas: '/useraccount/tabunganemas',
    UserAccount_TabunganEmas_Debits: '/useraccount/tabunganemas/debits',
    UserAccount_TabunganEmas_Debits_New: '/useraccount/tabunganemas/debits/new',
    UserAccount_TabunganEmas_Debits_id: '/useraccount/tabunganemas/debits/:id',
    UserAccount_TabunganEmas_Credits: '/useraccount/tabunganemas/credits',
    UserAccount_TabunganEmas_Credits_id: '/useraccount/tabunganemas/credits/:id',

    UserAccount_Link_Email: '/useraccount/link/email',
    UserAccount_Link_Email_SendCode: '/useraccount/link/email/sendcode',
    UserAccount_Link_Email_VerifyCode: '/useraccount/link/email/verifycode',
    UserAccount_Link_Email_Finish: '/useraccount/link/email/finish',

    UserAccount_Link_Phone: '/useraccount/link/phone',
    UserAccount_Link_Phone_Recaptcha: '/useraccount/link/phone/recaptcha',
    UserAccount_Link_Phone_VerifyCode: '/useraccount/link/phone/verifycode',
    UserAccount_Link_Phone_Finish: '/useraccount/link/phone/finish',

    UserAccount_Link_Google: '/useraccount/link/google',

    UserAccount_MembershipUpgrade_Selection: '/useraccount/membershipupgrade/selection',
    UserAccount_MembershipUpgrade_Payment: '/useraccount/membershipupgrade/payment',

    UserAccount_MembershipRenew_Selection: '/useraccount/membershiprenew/selection',
    UserAccount_MembershipRenew_Payment: '/useraccount/membershiprenew/payment',

    UserAccount_Bids: '/useraccount/bids',
    UserAccount_Bids_Unpaids: '/useraccount/bids/unpaids',
    UserAccount_Bids_Wins: '/useraccount/bids/wins',
    UserAccount_Bids_Loses: '/useraccount/bids/loses',

    UserAccount_Orders: '/useraccount/orders',
    UserAccount_Orders_ToPay: '/useraccount/orders/topay',
    UserAccount_Orders_ToPay_id: '/useraccount/orders/topay/:id',
    UserAccount_Orders_ToPay_id_Cancel: '/useraccount/orders/topay/:id/cancel',

    UserAccount_Orders_Installments: '/useraccount/orders/installments',
    UserAccount_Orders_Installments_Active: '/useraccount/orders/installments/active',
    UserAccount_Orders_Installments_Active_id: '/useraccount/orders/installments/active/:id',
    UserAccount_Orders_Installments_Expired: '/useraccount/orders/installments/expired',
    UserAccount_Orders_Installments_Expired_id: '/useraccount/orders/installments/expired/:id',
    //InstallmentDetail: '/useraccount/orders/installment/id',

    UserAccount_Orders_TopUp_Confirm: '/useraccount/orders/topup/confirm',
    UserAccount_Orders_Settlement_id: '/useraccount/orders/settlement/:id',
    UserAccount_Orders_Settlement_id_Pay: '/useraccount/orders/settlement/:id/pay',

    UserAccount_Orders_PrepareCollect: '/useraccount/orders/preparecollect',
    UserAccount_Orders_PrepareCollect_id: '/useraccount/orders/preparecollect/:id',
    UserAccount_Orders_PrepareCollect_MiscInvoices_id: '/useraccount/orders/preparecollect/miscinvoices/:id',
    UserAccount_Orders_PrepareCollect_Confirm: '/useraccount/orders/preparecollect/confirm',
    //OrdersPrepareCollectNew: '/useraccount/orders/preparecollect/new',
    UserAccount_Orders_ToCollectGroup_ToPay: '/useraccount/orders/tocollectgroup/topay',
    UserAccount_Orders_ToCollectGroup_ToPay_id: '/useraccount/orders/tocollectgroup/topay/:id',
    UserAccount_Orders_ToCollectGroup_ToPay_id_Cancel: '/useraccount/orders/tocollectgroup/topay/:id/cancel',
    UserAccount_Orders_ToCollect: '/useraccount/orders/tocollect',
    UserAccount_Orders_ToCollect_id: '/useraccount/orders/tocollect/:id',
    UserAccount_Orders_ToCollect_id_Cancel: '/useraccount/orders/tocollect/:id/cancel',
    //ToCollectDetail: '/useraccount/orders/tocollect/id',

    UserAccount_Orders_Completed: '/useraccount/orders/completed',
    UserAccount_Orders_Completed_id: '/useraccount/orders/completed/:id',
    UserAccount_Orders_Completed_Receipt: '/useraccount/orders/completed/receipt',
    UserAccount_Orders_Completed_Receipt_id: '/useraccount/orders/completed/receipt/:id',
    UserAccount_Orders_Cancelled: '/useraccount/orders/cancelled',
    UserAccount_Orders_Cancelled_id: '/useraccount/orders/cancelled/:id',

    // delivery
    UserAccount_Orders_PrepareDelivery: '/useraccount/orders/preparedelivery',
    UserAccount_Orders_PrepareDelivery_id: '/useraccount/orders/preparedelivery/:id',
    UserAccount_Orders_PrepareDelivery_Confirm: '/useraccount/orders/preparedelivery/confirm',
    UserAccount_Orders_ToDeliveryGroup_ToPay: '/useraccount/orders/todeliverygroup/topay',
    UserAccount_Orders_ToDeliveryGroup_ToPay_id: '/useraccount/orders/todeliverygroup/topay/:id',
    UserAccount_Orders_ToDeliveryGroup_ToPay_id_Cancel: '/useraccount/orders/todeliverygroup/topay/:id/cancel',
    UserAccount_Orders_ToDelivery: '/useraccount/orders/todelivery',
    UserAccount_Orders_ToDelivery_id: '/useraccount/orders/todelivery/:id',
    UserAccount_Orders_ToDeliveryGroups: '/useraccount/orders/todeliverygroups',
    UserAccount_Orders_ToDeliveryGroups_id: '/useraccount/orders/todeliverygroups/:id',
    UserAccount_Orders_Tracking: '/useraccount/orders/tracking',

    UserAccount_Reports_InvoiceCommissionByMonth: '/useraccount/reports/invoicecommissionbymonth',

    UserAccount_OrderItems: '/useraccount/orderitems',
    UserAccount_OrderItems_id: '/useraccount/orderitems/:id',
    UserAccount_Installments: '/useraccount/installments',
    UserAccount_Order_ChangeCustomer: '/useraccount/order/changecustomer',
    UserAccount_OrderItem_ChangeCustomer: '/useraccount/orderitem/changecustomer',

    UserAccount_MyCommissions: '/useraccount/mycommissions',

    UserAccount_Leaderboard: '/useraccount/leaderboard',

    UserAccount_DSs: '/useraccount/dss',

    // product
    Products: '/products',
    Products_id: '/products/:id',
    ProductDetail: '/productdetail',
    ProductDetail_id: '/productdetail/:id',
    ProductImage: '/productimage',
    GoldSets: '/goldsets',
    GoldSets_id: '/goldsets/:id',
    GoldSeriess: '/goldseriess',
    GoldSeriess_id: '/goldseriess/:id',

    Auctions: '/auctions',
    Auctions_id: '/auctions/:id',
    AuctionItems: '/auctionitems',
    AuctionItems_id: '/auctionitems/:id',

    Banners: '/banners',
    Banners_id: '/banners/:id',
    BannerLives: '/bannerlives',
    BannerLives_id: '/bannerlives/:id',

    Buy: '/buy',
    Buy_Cart: '/buy/cart',
    Buy_CheckOut: '/buy/checkout',
    Buy_CheckOutVip: '/buy/checkoutvip',
    Buy_SelectStore: '/buy/selectstore',
    Buy_SelectAddress: '/buy/selectaddress',
    Buy_SelectVoucher: '/buy/selectvoucher',
    Buy_SelectECashVoucher: '/buy/selectecashvoucher',
    PaymentMethod: '/paymentmethod',
    PayOrder: '/payorder',
    PayOrder_id: '/payorder/:id',
    TopUp: '/topup',
    TopUp_id: '/topup/:id',
    OpenTopUp: '/opentopup',
    SettlementGroup_id: '/settlementgroup/:id',
    Settlement_id: '/settlement/:id',

    FPXPayment: '/buyer/fpxpayment',
    FPXPayment_orderId: '/buyer/fpxpayment/orderId',
    PaymentPendingStatus: '/buyer/payment/pendingstatus',
    PaymentFail: '/buyer/payment/action/fail',
    PaymentSuccess: '/buyer/payment/action/success',
    PaymentAction: '/buyer/payment/action',
    PaymentAction_id: '/buyer/payment/action/:id',
    FPXPayTransaction: '/buyer/paytransaction',
    ManualPayment: '/buyer/manualpayment',

    Buyer_Payment_TabunganEmas: 'buyer/payment/tabunganemas',       // pass state

    Policies: '/policies',
    Policies_TermsofService: '/policies/termsofservice',
    Policies_PrivacyPolicy: '/policies/privacypolicy',

    HelpCenter: '/helpcenter',
    HelpCenter_Search: '/helpcenter/search',
    HelpCenter_Categorys: '/helpcenter/categorys',
    HelpCenter_Categorys_categoryId: '/helpcenter/categorys/:categoryId',
    HelpCenter_Categorys_categoryId_SubCategorys: '/helpcenter/categorys/:categoryId/subcategorys',
    HelpCenter_Categorys_categoryId_SubCategorys_subCategoryId: '/helpcenter/categorys/:categoryId/subcategorys/:subCategoryId',
    HelpCenter_Categorys_categoryId_SubCategorys_subCategoryId_Articles: '/helpcenter/categorys/:categoryId/subcategorys/:subCategoryId/articles',
    HelpCenter_Categorys_categoryId_SubCategorys_subCategoryId_Articles_articleId: '/helpcenter/categorys/:categoryId/subcategorys/:subCategoryId/articles/:articleId',
};